



























































import Vue from "vue";
// import i18n from "vue-i18n";

export default Vue.extend({
  data: () => ({
    dialog: false,
    autoplay: false,
    playState: false
  }),
  props: {
    exampleProp: String,
    maxWidth: String,
    videoSrc: String,
    videoID: String
  },
  methods: {
    closeDialog: function() {
      this.dialog = false;
      const myVideo: HTMLMediaElement = document.getElementById(this.videoID) as HTMLMediaElement;
      myVideo.pause();
      this.playState = false;
    },
    playPause: function() {
      const myVideo: HTMLMediaElement = document.getElementById(this.videoID) as HTMLMediaElement;
      if (myVideo.paused) {
        myVideo.play();
        this.playState = true;
      } else {
        myVideo.pause();
        this.playState = false;
      }
    }
  }
});
