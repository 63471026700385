






















































































































































































import Vue from "vue";
import { mapState } from "vuex";
import VideoDialog from "@/components/VideoDialog.vue";

export default Vue.extend({
  name: "Goals",
  data: () => ({
    intervalPercentage: 0,
    intervalPercenageSpent: 0,
    valuePercentageDonated: 0,
    valuePercentageSpent: 0,
    intervalCurrent: 0,
    intervalCurrentSpent: 0,
    valueCurrent: 0,
    valueCurrentSpent: 0
  }),
  components: { VideoDialog },
  methods: {
    scrollTo: function(hashtag) {
      setTimeout(() => {
        this.$vuetify.goTo(hashtag, {
          duration: 300,
          offset: 0,
          easing: "easeInOutCubic"
        });
      }, 1);
    }
  },
  computed: {
    ...mapState(["milestones"]),
    ...mapState(["goal"]),
    target: function(): number {
      return Number(this.goal[0].target);
    },
    current: function(): number {
      return Number(this.goal[0].current);
    },
    currentPercentage: function(): number {
      return Number(((this.current / this.target) * 100).toFixed(0));
    },
    // spent: function(): number {
    //   return Number(this.goal[0].target);
    // },
    currentSpent: function(): number {
      return Number(this.goal[0].spent);
    },
    currentPercentageSpent: function(): number {
      return Number(((this.currentSpent / this.current) * 100).toFixed(0));
    }
  },
  watch: {},
  beforeDestroy() {
    clearInterval(this.intervalPercentage);
    clearInterval(this.intervalCurrent);
    clearInterval(this.intervalPercentageSpent);
    clearInterval(this.intervalCurrentSpent);
  },
  mounted() {
    this.$store.dispatch("loadMilestones");
    this.$store.dispatch("loadGoal");

    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1000);
    }

    (this.intervalPercentage = Number(
      setInterval(() => {
        if (this.valuePercentageDonated >= this.currentPercentage) {
          return (this.valuePercentageDonated = this.currentPercentage);
        }
        this.valuePercentageDonated += 2;
      }, 200)
    )),
      (this.intervalCurrent = Number(
        setInterval(() => {
          if (this.valueCurrent >= this.current) {
            return (this.valueCurrent = this.current);
          }
          this.valueCurrent += 200;
        }, 200)
      )),
      (this.intervalPercenageSpent = Number(
        setInterval(() => {
          if (this.valuePercentageSpent >= this.currentPercentageSpent) {
            return (this.valuePercentageSpent = this.currentPercentageSpent);
          }
          this.valuePercentageSpent += 2;
        }, 200)
      )),
      (this.intervalCurrentSpent = Number(
        setInterval(() => {
          if (this.valueCurrentSpent >= this.currentSpent) {
            return (this.valueCurrentSpent = this.currentSpent);
          }
          this.valueCurrentSpent += 200;
        }, 200)
      ));
  }
});
